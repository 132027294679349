import MessageMap from 'i18n/i18n.d';

const en: MessageMap = {
  downloadModal: {
    titleHeader: 'Email report now',
    description: 'The file containing the filtered data will be sent by email.',
    fieldEmail: 'Your email',
    fieldFileFormat: 'File format',
    sendButton: 'Send',
    cancelButton: 'Cancel',
  },
  scheduleModal: {
    titleHeader: 'Schedule delivery',
    description: 'Receive the file in your email',
    fieldFileFormat: 'File format',
    fieldFrequency: 'Frequency',
    subFieldFrequency: 'Recurs every week on:',
    fieldDelivery: 'Delivery time',
    fieldEmail: 'Email',
    sendButton: 'Schedule',
    cancelButton: 'Cancel',
  },
};

export default en;
